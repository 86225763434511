<template>
  <base-section
    id="pro-features"
  >
    <!--
    <v-img
      :src="require('@/assets/logo.svg')"
      class="mx-auto mb-8"
      max-width="128"
    />
    -->

    <base-section-heading :title="$t('landing-compare.title')">
      <div v-html="$t('landing-compare.subtitle')" />
    </base-section-heading>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th />
                <th class="body-1 font-weight-bold text-center">
                  {{ $t('landing-compare.standard') }}
                </th>
                <th class="body-1 font-weight-bold text-center">
                  {{ $t('landing-compare.pro') }}
                </th>
                <th class="body-1 font-weight-bold text-center">
                  {{ $t('landing-compare.enterprise') }}
                </th>
              </tr>
            </thead>

            <tbody class="body-1">
              <tr
                v-for="([name, one, two, three], i) in features"
                :key="i"
              >
                <td v-text="$t(name)" />

                <td class="text-center font-weight-bold">
                  <template v-if="typeof one === 'boolean'">
                    <v-icon :color="one ? 'success' : 'error'">
                      mdi-{{ one ? 'check' : 'close' }}
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ $t(one) }}
                  </template>
                </td>

                <td class="text-center font-weight-bold">
                  <template v-if="typeof two === 'boolean'">
                    <v-icon :color="two ? 'success' : 'error'">
                      mdi-{{ two ? 'check' : 'close' }}
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ $t(two) }}
                  </template>
                </td>

                <td class="text-center font-weight-bold">
                  <template v-if="typeof three === 'boolean'">
                    <v-icon :color="three ? 'success' : 'error'">
                      mdi-{{ three ? 'check' : 'close' }}
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ $t(three) }}
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  // import utilities from '@/mixins/utilities'
  import { useSubscriptionStore } from '@/stores/SubscriptionStore'
  // import { storeToRefs } from 'pinia'

  const subscription = useSubscriptionStore()
  // const { price } = storeToRefs(useSubscriptionStore())

  export default {
    name: 'SectionProChart',

    mixins: [
      // utilities,
    ],

    data: () => ({
    }),

    computed: {
      features () {
        // console.log(pricingType)
        const s = subscription
        const f = s.features
        return [
          ['landing-compare.projects', f.standard.projects, f.professional.projects, f.enterprise.projects],
          ['landing-compare.forms', 'landing-compare.unlimited', 'landing-compare.unlimited', 'landing-compare.unlimited'],
          ['landing-compare.builder', true, true, true],
          ['landing-compare.collector', 'landing-compare.unlimited', 'landing-compare.unlimited', 'landing-compare.unlimited'],
          ['landing-compare.webuser', 'landing-compare.unlimited', 'landing-compare.unlimited', 'landing-compare.unlimited'],
          ['landing-compare.submission', f.standard.subs, f.professional.subs, f.enterprise.subs],
          ['landing-compare.support', 'landing-compare.email', 'landing-compare.email', 'landing-compare.enterprisesupport'],
          // ['Bugfixes and Issues', true, true, true, true],
          [
            'landing-compare.price',
            `$${s.price[s.pricingType].standard}`,
            `$${s.price[s.pricingType].professional}`,
            `$${s.price[s.pricingType].enterprise}`,
          ],
        ]
      },
    },

  }
</script>
